import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload week starts today.`}</em></p>
    <p>{`DB Bench Press 12-12-12-12`}</p>
    <p>{`DB Rows 12-12-12-12`}</p>
    <p>{`then,`}</p>
    <p>{`400m Run`}</p>
    <p>{`30-Ring Pushups`}</p>
    <p>{`30-Ring Rows`}</p>
    <p>{`30-Situps`}</p>
    <p>{`400M Run`}</p>
    <p>{`20-Ring Pushups`}</p>
    <p>{`20-Ring Rows`}</p>
    <p>{`20-Situps`}</p>
    <p>{`400M Run`}</p>
    <p>{`10-Ring Pushups`}</p>
    <p>{`10-Ring Rows`}</p>
    <p>{`10-Situps`}</p>
    <p>{`For time.`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start July 1st.  Email Eric at
fallscitystrength\\@gmail.com to sign up.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      